.redesignedSwitcher {
    background-color: var(--surface-default) !important;
}

.redesignedSwitcher,
.redesignedSwitcher input {
    border-radius: 0 !important;
}

.redesignedSwitcher input:not:checked {
    cursor: pointer;
}

.redesignedSwitcher input:checked {
    background-color: var(--on-surface-text-on-primary) !important;
}

.redesignedSwitcher input:checked + p {
    color: var(--surface-default) !important;
}
