.loaderWrapper {
    width: 100vw;
    height: 100vh;
    background: var(--surface-main);
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 40px;
    opacity: 0;
    animation: pulse 1s 500ms infinite alternate;
}

@keyframes pulse {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.6;
    }
}
