.container {
    max-width: 400px;
    margin: 0 auto;

    /* padding top = header height + 24px */
    padding: 78px var(--default-indent) 32px;
}

.containerFixedButton {
    padding-bottom: 128px;
}

@media screen and (min-width: 768px) {
    .container {
        max-width: calc(576px + var(--default-indent));
    }
}
