/* hard color */
.ReactModal__Content {
    position: absolute;
    background: var(--surface-main);
    overflow: auto;
    outline: none;
    padding: 16px;
    border: none;
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    width: 100%;
}

.ReactModal__Content__withTransparency {
    top: 64px;
    border-radius: 12px 12px 0 0 !important;
}

.ReactModal__Content__withTransparency36 {
    top: 36px;
    border-radius: 12px 12px 0 0 !important;
    padding: 0;
}

.ReactModal__Overlay {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    inset: 0;
    background-color: rgb(255 255 255 / 75%);
    transition: 0.3s;
}

.ReactModal__Overlay__isClose {
    opacity: 0;
    transform: translate(0, 20px);
    visibility: hidden;
}

.ReactModal__Overlay__isOpen {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
}

.ReactModal__Overlay__withTransparency {
    background: rgb(0 0 0 / 50%) !important;
}

.ReactModal__Content__exclusiveOffer {
    padding: 0 !important;
}

.ReactModal__Content__paddingTop32 {
    padding-top: 32px;
}

.ReactModal__Content__paddingTop78 {
    padding-top: 78px;
}

.ReactModal__Content__noOverflow {
    overflow: visible;
}

@media screen and (min-width: 1024px) {
    .ReactModal__Content__desktop {
        top: 50%;
        right: unset;
        bottom: unset;
        left: 50%;
        width: 376px;
        height: 591px;
        border-radius: 12px !important;
        transform: translate(-50%, -50%);
    }
}
