.notificationModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 343px;
    height: auto;
    padding: 24px 12px 12px;
    border-radius: 12px;
    text-align: center;
    background-color: var(--surface-main);
}

.Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 50%);
    z-index: 10000;
}

.notificationModalIcon {
    display: block;
    margin: 0 auto 12px;
}

.ReactModalBodyOpen {
    overflow: hidden;
}

.bold {
    font-weight: bold;
}
