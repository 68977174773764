.logo {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    height: 28px;
    text-align: center;
    color: var(--logo-color);
}

.logo svg {
    height: 28px;
}

.logo.isOnboarding {
    height: 22px;
}

.logoImgDefault {
    max-height: 28px;
}

.logoImgDefault svg {
    height: 28px;
}

.logoImgOnboarding {
    height: 20px;
}

.logoFooter {
    height: 40px;
}

.centered {
    margin: 0 auto;
}

.biggerLogoText {
    font-size: 16px !important;
    line-height: 24px !important;
}
