.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.headerContainer {
    position: relative;
    padding: 8px var(--default-indent) 0;
    min-height: 54px;
    display: flex;
    flex-direction: column;
}

.top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageCounter {
    font-weight: 700;
    font-size: 12px;
}

.pageSeparator {
    margin: 0 3px;
}

.pageStart,
.pageSeparator {
    color: var(--on-surface-text-secondary-subdued);
}

.pageEnd {
    color: var(--text-main-secondary);
}

.progressBox {
    margin-top: 8px;
    position: relative;
    width: 100%;
    height: 6px;
    background-color: var(--surface-default);
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--on-surface-text-on-primary);
    transition: width 0.2s linear;
}

.skipButton {
    min-width: 107px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--on-surface-text-main);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.skipButton svg {
    margin-left: 6px;
}

.backButton {
    display: block;
    padding: 2px;
    background-color: transparent;
}

.text-main path {
    fill: var(--on-surface-text-main) !important;
}

.text-secondary path {
    fill: var(--on-surface-text-secondary) !important;
}

.surface-default path {
    fill: var(--surface-default) !important;
}
