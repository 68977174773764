/* ToDo: remove top padding from container in one of future refactor */

.default {
    padding: 86px var(--default-indent) 24px;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

.withoutHeader {
    padding: 16px var(--default-indent) 24px;
}

.fullPadding {
    padding: 86px var(--default-indent) 106px;
}

.noBottomPadding {
    padding-bottom: 0;
}

@media screen and (min-width: 1024px) {
    .default {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: unset;
        position: relative;
    }
}
