.errorWrapper {
    padding: 0;
    display: flex;
}

.errorContainer {
    margin: auto;
    max-width: 432px;
    padding: 0 24px;
}
