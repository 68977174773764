.optionWrapper {
    position: relative;
}

.input {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.option {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 80px;
    max-height: 96px;
    border: 2px transparent solid;
    background-color: var(--surface-default);
    padding: 16px 20px;
}

.option.small {
    min-height: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.option.medium {
    min-height: 52px;
    padding: 14px 15px;
}

.option.large .contentWrapper,
.option.extraLarge .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option.extraLarge {
    min-height: 96px;
}

.option.tiled {
    padding: 16px;
}

.optionWithRightImg {
    padding-right: 0;
}

.optionChecked {
    border: 2px var(--primary-default) solid;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
    border: 2px var(--border-default) solid;
    border-radius: 4px;
    margin-right: 14px;
}

.optionChecked .checkbox {
    background: var(--primary-default);
    border: none;
}

.contentWrapper {
    width: 100%;
}

.optionChecked .contentWrapper:last-child path {
    fill: var(--primary-default);
}

.optionChecked .contentWrapper svg {
    fill: var(--primary-default);
}
