.container {
    padding: 10px 8px 8px;
    display: flex;
    border-radius: 12px;
}

.imageWrapper,
.imageWrapper img {
    width: 20px;
    height: 20px;
}

.imageWrapper {
    margin-right: 4px;
}

.imageWrapper svg {
    margin-top: -2px;
}

.bgDefault {
    background: #f4f1ed;
}

.bgEasy {
    background: var(--decorative-safe-surface);
}

.bgNormal {
    background: var(--success-surface);
}

.bgWarning {
    background: var(--warning-surface);
}

.bgObese {
    background: var(--critical-surface);
}

.sloganDefault {
    text-transform: uppercase;
}

.title {
    line-height: 16px;
    font-weight: 800;
    font-size: 11px;
}
